import { IFilter } from 'transaction/transaction.type';
import { TFilterAction } from 'transaction/filter/filter.type';

import { initialTXNFilterState } from './filter.data';

function filterReducer(state: IFilter, action: TFilterAction): IFilter {
  switch (action.type) {
    case 'SET_TXN_FILTER': {
      return { ...state, transactionFilter: action.payload };
    }

    case 'RESET_TXN_FILTER': {
      return { ...state, transactionFilter: { ...initialTXNFilterState } };
    }

    case 'SET_START_DATE': {
      return { ...state, transactionFilter: { ...state.transactionFilter, startDate: action.payload } };
    }

    case 'SET_END_DATE': {
      return { ...state, transactionFilter: { ...state.transactionFilter, endDate: action.payload } };
    }

    case 'SET_INPUT_FIELD': {
      return { ...state, transactionFilter: { ...state.transactionFilter, ...action.payload } };
    }

    case 'SET_FILTERED': {
      return { ...state, transactionFilter: { ...state.transactionFilter, filtered: true } };
    }

    case 'RESET_FILTERED': {
      return { ...state, transactionFilter: { ...state.transactionFilter, filtered: false } };
    }

    default: {
      throw new Error('Please select the appropriate action');
    }
  }
}

export default filterReducer;
