import React, { createContext, useContext, useReducer } from 'react';

import { IPage, ITransaction } from 'transaction/transaction.type';

import transactionReducer, {
  ITransactionState,
  TTransactionDispatch,
  initialTransactionsState,
} from './transaction.reducer';
import { resetFetching, setFetching, setPaging, setTransactions } from './transaction.action';

export interface IUseTransactionContextReturn {
  state: ITransactionState;
  dispatch: TTransactionDispatch;
  actions: {
    setFetching: () => void;
    resetFetching: () => void;
    setPaging: (paging: IPage) => void;
    setTransactions: (transactions: ITransaction[]) => void;
  };
}

const TransactionStateContext = createContext<ITransactionState | undefined>(undefined);
const TransactionDispatchContext = createContext<TTransactionDispatch | undefined>(undefined);

function TransactionProvider({ children }: { children: React.ReactNode }): JSX.Element {
  const [state, dispatch] = useReducer(transactionReducer, initialTransactionsState);

  return (
    <TransactionStateContext.Provider value={state}>
      <TransactionDispatchContext.Provider value={dispatch as any}>{children}</TransactionDispatchContext.Provider>
    </TransactionStateContext.Provider>
  );
}

function useTransactionState(): ITransactionState {
  const context = useContext(TransactionStateContext);

  if (context === undefined) {
    throw new Error('Must be within the transaction context');
  }

  return context;
}

function useTransactionDispatch(): TTransactionDispatch {
  const context = useContext(TransactionDispatchContext);

  if (context === undefined) {
    throw new Error('Must be within the transaction context');
  }

  return context;
}

function useTransactionContext(): IUseTransactionContextReturn {
  const dispatch = useTransactionDispatch();
  const state = useTransactionState();

  const actions = {
    setTransactions: (transactions: ITransaction[]) => {
      dispatch(setTransactions(transactions));
    },
    setFetching: () => {
      dispatch(setFetching());
    },
    resetFetching: () => {
      dispatch(resetFetching());
    },
    setPaging: (paging: IPage) => {
      dispatch(setPaging(paging));
    },
  };

  return { state, dispatch, actions };
}

export { TransactionProvider, useTransactionDispatch, useTransactionState, useTransactionContext };
