import React from 'react';

import { TSVGComponent } from 'app/app.type';

const EyeIcon: TSVGComponent = ({ fill = 'black', stroke = 'white', width = '17', height = '12', ...rest }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...rest}
    >
      <path
        d='M16.0595 5.48704C15.467 4.00215 14.4383 2.71801 13.1007 1.79334C11.763 0.868674 10.175 0.344004 8.53292 0.28418C6.89083 0.344004 5.30283 0.868674 3.96518 1.79334C2.62753 2.71801 1.59887 4.00215 1.00633 5.48704C0.966314 5.59427 0.966314 5.71169 1.00633 5.81892C1.59887 7.30381 2.62753 8.58795 3.96518 9.51262C5.30283 10.4373 6.89083 10.962 8.53292 11.0218C10.175 10.962 11.763 10.4373 13.1007 9.51262C14.4383 8.58795 15.467 7.30381 16.0595 5.81892C16.0995 5.71169 16.0995 5.59427 16.0595 5.48704ZM8.53292 10.0456C5.86285 10.0456 3.04163 8.12751 2.01894 5.65298C3.04163 3.17845 5.86285 1.26033 8.53292 1.26033C11.203 1.26033 14.0242 3.17845 15.0469 5.65298C14.0242 8.12751 11.203 10.0456 8.53292 10.0456Z'
        fill={fill}
      />
      <path
        d='M16.0595 5.48704C15.467 4.00215 14.4383 2.71801 13.1007 1.79334C11.763 0.868674 10.175 0.344004 8.53292 0.28418C6.89083 0.344004 5.30283 0.868674 3.96518 1.79334C2.62753 2.71801 1.59887 4.00215 1.00633 5.48704C0.966314 5.59427 0.966314 5.71169 1.00633 5.81892C1.59887 7.30381 2.62753 8.58795 3.96518 9.51262C5.30283 10.4373 6.89083 10.962 8.53292 11.0218C10.175 10.962 11.763 10.4373 13.1007 9.51262C14.4383 8.58795 15.467 7.30381 16.0595 5.81892C16.0995 5.71169 16.0995 5.59427 16.0595 5.48704ZM8.53292 10.0456C5.86285 10.0456 3.04163 8.12751 2.01894 5.65298C3.04163 3.17845 5.86285 1.26033 8.53292 1.26033C11.203 1.26033 14.0242 3.17845 15.0469 5.65298C14.0242 8.12751 11.203 10.0456 8.53292 10.0456Z'
        stroke={stroke}
      />
      <path
        d='M8.53249 2.72461C7.93466 2.72461 7.35024 2.89636 6.85316 3.21814C6.35607 3.53992 5.96864 3.99728 5.73986 4.53238C5.51108 5.06748 5.45122 5.65629 5.56785 6.22436C5.68448 6.79242 5.97237 7.31421 6.3951 7.72376C6.81784 8.13331 7.35644 8.41222 7.94279 8.52521C8.52914 8.63821 9.13691 8.58021 9.68924 8.35857C10.2416 8.13692 10.7137 7.76158 11.0458 7.28C11.3779 6.79842 11.5552 6.23224 11.5552 5.65305C11.5552 4.87638 11.2368 4.13152 10.6699 3.58233C10.103 3.03314 9.33417 2.72461 8.53249 2.72461ZM8.53249 7.60534C8.13393 7.60534 7.74433 7.49084 7.41294 7.27632C7.08155 7.0618 6.82326 6.75689 6.67074 6.40016C6.51821 6.04342 6.47831 5.65088 6.55606 5.27217C6.63382 4.89347 6.82574 4.5456 7.10757 4.27257C7.38939 3.99953 7.74846 3.8136 8.13936 3.73827C8.53026 3.66294 8.93544 3.7016 9.30366 3.84936C9.67188 3.99713 9.9866 4.24736 10.208 4.56841C10.4295 4.88946 10.5476 5.26692 10.5476 5.65305C10.5476 6.17083 10.3353 6.6674 9.95742 7.03352C9.57951 7.39965 9.06695 7.60534 8.53249 7.60534Z'
        fill={fill}
      />
      <path
        d='M8.53249 2.72461C7.93466 2.72461 7.35024 2.89636 6.85316 3.21814C6.35607 3.53992 5.96864 3.99728 5.73986 4.53238C5.51108 5.06748 5.45122 5.65629 5.56785 6.22436C5.68448 6.79242 5.97237 7.31421 6.3951 7.72376C6.81784 8.13331 7.35644 8.41222 7.94279 8.52521C8.52914 8.63821 9.13691 8.58021 9.68924 8.35857C10.2416 8.13692 10.7137 7.76158 11.0458 7.28C11.3779 6.79842 11.5552 6.23224 11.5552 5.65305C11.5552 4.87638 11.2368 4.13152 10.6699 3.58233C10.103 3.03314 9.33417 2.72461 8.53249 2.72461ZM8.53249 7.60534C8.13393 7.60534 7.74433 7.49084 7.41294 7.27632C7.08155 7.0618 6.82326 6.75689 6.67074 6.40016C6.51821 6.04342 6.47831 5.65088 6.55606 5.27217C6.63382 4.89347 6.82574 4.5456 7.10757 4.27257C7.38939 3.99953 7.74846 3.8136 8.13936 3.73827C8.53026 3.66294 8.93544 3.7016 9.30366 3.84936C9.67188 3.99713 9.9866 4.24736 10.208 4.56841C10.4295 4.88946 10.5476 5.26692 10.5476 5.65305C10.5476 6.17083 10.3353 6.6674 9.95742 7.03352C9.57951 7.39965 9.06695 7.60534 8.53249 7.60534Z'
        stroke={stroke}
      />
    </svg>
  );
};

export default EyeIcon;
