import React, { createContext } from 'react';

import { IResponse } from 'auth/auth.type';

export interface ITransactionFilter {
  error: any;
  sidebarExpanded: boolean;
  timezone: Record<string, string>;
}

export interface IAuthProvider {
  children: React.ReactNode;
}

export type TAppAction =
  | { type: 'TOGGLE_SIDEBAR' }
  | { type: 'SET_ERROR'; payload: any }
  | { type: 'SET_TIMEZONE'; payload: Record<string, string> };

export type TAppDispatch = (action: TAppAction) => void;

const AppDispatchContext = createContext<TAppDispatch | undefined>(undefined);
const AppStateContext = createContext<ITransactionFilter | undefined>(undefined);

const appReducer = (state: ITransactionFilter, action: TAppAction): ITransactionFilter => {
  switch (action.type) {
    case 'TOGGLE_SIDEBAR': {
      return { ...state, sidebarExpanded: !state.sidebarExpanded };
    }

    case 'SET_ERROR': {
      return {
        ...state,
        error: action.payload,
      };
    }

    case 'SET_TIMEZONE': {
      return {
        ...state,
        timezone: action.payload,
      };
    }

    default: {
      throw new Error('Please select the appropriate action');
    }
  }
};

const AppProvider = ({ children }: IAuthProvider): JSX.Element => {
  const [state, dispatch] = React.useReducer(appReducer, {
    error: null,
    sidebarExpanded: false,
    timezone: {},
  });

  return (
    <AppStateContext.Provider value={state}>
      <AppDispatchContext.Provider value={dispatch as any}>{children}</AppDispatchContext.Provider>
    </AppStateContext.Provider>
  );
};

const useAppState = (): ITransactionFilter => {
  const context = React.useContext(AppStateContext);

  if (context === undefined) {
    throw new Error('useAppState must be used within a App Provider');
  }

  return context;
};

const useAppDispatch = (): TAppDispatch => {
  const context = React.useContext(AppDispatchContext);

  if (context === undefined) {
    throw new Error('Must be used within a App Provider');
  }

  return context;
};

const useApp = () => {
  const state = useAppState();
  const dispatch = useAppDispatch();
  const call = async <T extends IResponse>(promise: Promise<T>) => {
    const { data, error } = await promise;

    if (error) {
      dispatch({ type: 'SET_ERROR', payload: error });

      return { data, error };
    }

    dispatch({ type: 'SET_ERROR', payload: null });

    return { data, error };
  };

  return { ...state, call, dispatch };
};

export { AppProvider, useAppDispatch, useAppState, useApp };
