import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { ROUTES } from 'app/app.constant';
import useLocale from 'app/hooks/use-locale';

import { Input } from '@ui/form';
import DismissibleAlert from '@ui/alert/alert';

import { login } from 'auth/auth.api';
import { EParam } from 'auth/auth.enum';
import { useAuthDispatch, useAuthState } from 'auth/auth.context';
import { authenticate, setAuthenticationFailure, setAuthenticationSuccess } from 'auth/auth.actions';

import images from 'assets/images';
import { useApp } from 'app/app.context';
import queryString from 'helpers/query.helper';
import { EStorageKey, securedLS } from 'helpers/storage.helper';

interface FormElements extends HTMLFormControlsCollection {
  email: HTMLInputElement;
  password: HTMLInputElement;
}

interface SignInFormElement extends HTMLFormElement {
  readonly elements: FormElements;
}

const Login = (): JSX.Element => {
  const { call } = useApp();
  const history = useHistory();
  const dispatch = useAuthDispatch();
  const [error, setError] = useState('');
  const { authText, appText } = useLocale();
  const { isAuthenticating } = useAuthState();
  const location = useLocation<{ from: string }>();

  useInitialLoginLoad();
  const from = securedLS.get(EStorageKey.LAST_VISITED_URL).data
    ? securedLS.get(EStorageKey.LAST_VISITED_URL).data
    : location?.state?.from;

  const resetError = () => setError('');

  const handleSubmit = async (e: React.FormEvent<SignInFormElement>) => {
    e.preventDefault();
    const email = e.currentTarget.elements.email.value.trim();
    const password = e.currentTarget.elements.password.value.trim();

    if (!(email && password)) {
      return setError(authText('ALL_REQUIRED'));
    }

    dispatch(authenticate());
    const { error, data } = await call(login({ email, password }));

    if (error) {
      setError(error.message || appText('INCORRECT_CREDENTIALS'));

      return dispatch(setAuthenticationFailure());
    }

    dispatch(setAuthenticationSuccess(data));
    securedLS.set(EStorageKey.EMAIL, email);

    return history.push(from || ROUTES.ROOT);
  };

  return (
    <div className='login-wrapper'>
      <div className='logo-container'>
        <div className='logo-wrapper'>
          <img src={images.LOGO} alt='Machnet' />
        </div>
      </div>
      <form className='login-form-wrapper' onSubmit={handleSubmit} onChange={resetError}>
        <h1 className='title'>{authText('LOGIN')}</h1>
        {queryString().has(EParam.EXPIRED) ? <DismissibleAlert /> : null}
        <Input
          name='email'
          type='email'
          title='Email'
          label={authText('EMAIL')}
          placeholder={authText('ENTER_EMAIL')}
        />
        <Input
          name='password'
          type='password'
          title='Password'
          label={authText('PASSWORD')}
          placeholder={authText('ENTER_PASSWORD')}
        />
        {error && <p className='error'>{error}</p>}
        <div className='action-wrapper'>
          <button className='btn btn-primary' type='submit' value='submit' disabled={isAuthenticating}>
            {authText('LOGIN')}
          </button>
        </div>
      </form>
    </div>
  );
};

export default Login;

const useInitialLoginLoad = () => {
  const dispatch = useAuthDispatch();

  useEffect(() => {
    dispatch(setAuthenticationFailure());
  }, [dispatch]);
};
