export const ROUTES = {
  ROOT: '/',
  LOGIN: '/login',
  NOT_FOUND: '/404',
  CLIENTS: '/clients',
  DASHBOARD: '/dashboard',
  TRANSACTIONS: '/transactions',
};

export const LOCAL_STORAGE = {
  TOKEN: 'token',
};
