import * as http from 'api/http.api';

import urls from './auth-api-url';
import { ILoginCredential, ILoginResponse, ILogoutResponse } from './auth.type';

export const login = (loginCredential: ILoginCredential): Promise<ILoginResponse> => {
  return http.post(urls.LOGIN, { data: loginCredential, auth: false });
};

export const logout = (): Promise<ILogoutResponse> => {
  return http.get(urls.LOGOUT);
};
