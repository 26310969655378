export enum EChangeAction {
  REFUND = 'REFUND',
  CANCEL = 'CANCEL',
  UNHOLD = 'UNHOLD',
  RETURN = 'RETURN',
}

export enum ETransactionType {
  CREDIT = 'CREDIT',
  DEBIT = 'DEBIT',
}

export enum EFundingSource {
  CARD = 'CARD',
  BANK = 'BANK',
}

export enum ETransactionStatus {
  HOLD = 'HOLD',
  FAILED = 'FAILED',
  PENDING = 'PENDING',
  REFUNDED = 'REFUNDED',
  CANCELED = 'CANCELED',
  RETURNED = 'RETURNED',
  COMPLETED = 'COMPLETED',
  PROCESSED = 'PROCESSED',
  INITIATED = 'INITIATED',
}
