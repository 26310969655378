import SecureLS from 'secure-ls';

import { withData, withError, IDataReturn, IErrorReturn } from './response.helper';

export enum EStorageKey {
  TOKEN = '__t',
  TIME_ZONE = '__tz',
  CURRENT_USER = '__cu',
  EMAIL = '__e',
  LAST_VISITED_URL = '__lvu',
}

const message = {
  NO_LOCAL_STORAGE: 'No local storage found.',
  INVALID_KEY_FORMAT: 'Invalid key format',
};

type TStorageReturn = IDataReturn | IErrorReturn;

export const parse = (data: string | null): TStorageReturn => {
  try {
    if (typeof data === 'string') {
      const parsedData = JSON.parse(data);

      return withData(parsedData);
    }

    return withError(message.INVALID_KEY_FORMAT);
  } catch (error: any) {
    return withError(error);
  }
};

const ls = new SecureLS({
  encodingType: 'des',
  isCompression: true,
  encryptionSecret: process.env.ENCRYPTION_SECRET,
});

const hasLocalStorage = !!window.localStorage;

export const securedLS = {
  set<D>(key: EStorageKey, value: D): TStorageReturn {
    if (!hasLocalStorage) {
      return withError(message.NO_LOCAL_STORAGE);
    }

    return withData(ls.set(key, value));
  },

  get<T>(key: EStorageKey): TStorageReturn {
    if (!hasLocalStorage) {
      return withError(message.NO_LOCAL_STORAGE);
    }

    try {
      return withData<T>(ls.get(key));
    } catch {
      return withError('');
    }
  },

  clear(key?: EStorageKey): TStorageReturn {
    if (!hasLocalStorage) {
      return withError(message.NO_LOCAL_STORAGE);
    }

    if (key) {
      return withData(localStorage.removeItem(key));
    }

    return withData(ls.removeAll());
  },
};

export const clearAuthData = (): void => {
  securedLS.clear(EStorageKey.TOKEN);
  securedLS.clear(EStorageKey.CURRENT_USER);
  securedLS.clear(EStorageKey.EMAIL);
};
