import React from 'react';

import { ICONS } from 'assets/icons';

interface ILoaderProps {
  message?: string;
}

const Loader: React.FC<ILoaderProps> = ({ message = 'Please wait Loading...' }) => {
  return (
    <div className='loader'>
      <div>
        <ICONS.SPINNER_BLACK width='48' height='48' />
      </div>
      <p className='text-center'>{message}</p>
    </div>
  );
};

export default Loader;
