import { IPage, ITransaction } from 'transaction/transaction.type';
import { TTransactionActions } from './transaction.reducer';

export const setTransactions = (transactions: ITransaction[]): TTransactionActions => {
  return {
    type: 'SET_TRANSACTIONS',
    payload: transactions,
  };
};

export const setFetching = (): TTransactionActions => {
  return {
    type: 'SET_FETCHING',
  };
};

export const resetFetching = (): TTransactionActions => {
  return {
    type: 'RESET_FETCHING',
  };
};

export const setPaging = (paging: IPage): TTransactionActions => {
  return {
    type: 'SET_PAGING',
    payload: paging,
  };
};
