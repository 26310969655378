class QueryString {
  private url: string;
  private urlParams: URLSearchParams;

  constructor(url?: string) {
    this.url = url || window.location.search;

    this.urlParams = new URLSearchParams(this.url);
  }

  public has(key: string) {
    return this.urlParams.has(key);
  }

  public get(key: string) {
    return this.urlParams.get(key);
  }

  public getAll(key: string) {
    return this.urlParams.getAll(key);
  }

  public append(key: string, value: string) {
    return this.urlParams.append(key, value);
  }

  public toString() {
    return this.urlParams.toString();
  }
}

const queryString = (url?: string): QueryString => new QueryString(url);

export default queryString;
