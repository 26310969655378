import React, { createContext } from 'react';

import { AUTH } from 'auth/auth.constant';
import { TAuthAction, IAuthContextType, TDispatch, IAuthProvider } from 'auth/auth.type';

const initialState: IAuthContextType = {
  token: '',
  user: {
    roles: [],
    company: '',
    lastName: '',
    firstName: '',
  },
  authenticated: false,
  isAuthenticating: false,
};

const AuthDispatchContext = createContext<TDispatch | undefined>(undefined);
const AuthStateContext = createContext<IAuthContextType | undefined>(undefined);

function authReducer(state: IAuthContextType, action: TAuthAction) {
  switch (action.type) {
    case AUTH.AUTHENTICATE: {
      return { ...state, isAuthenticating: true };
    }

    case AUTH.AUTHENTICATE_SUCCESS: {
      return {
        ...state,
        authenticated: true,
        isAuthenticating: false,
        token: action.payload.token,
        user: action.payload.user,
      };
    }

    case AUTH.AUTHENTICATE_FAILURE: {
      return { ...state, authenticated: false, isAuthenticating: false };
    }

    default: {
      throw new Error('Unhandled action type');
    }
  }
}

function AuthProvider({ children }: IAuthProvider): JSX.Element {
  const [state, dispatch] = React.useReducer(authReducer, initialState);

  return (
    <AuthStateContext.Provider value={state}>
      <AuthDispatchContext.Provider value={dispatch as any}>{children}</AuthDispatchContext.Provider>
    </AuthStateContext.Provider>
  );
}

function useAuthState(): IAuthContextType {
  const context = React.useContext(AuthStateContext);

  if (context === undefined) {
    throw new Error('useAuthState must be used within a Auth Provider');
  }

  return context;
}

function useAuthDispatch(): TDispatch {
  const context = React.useContext(AuthDispatchContext);

  if (context === undefined) {
    throw new Error('Must be used within a Auth Provider');
  }

  return context;
}

export { AuthProvider, useAuthDispatch, useAuthState };
