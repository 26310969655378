import React from 'react';
import { Router } from 'react-router-dom';

import AppRoute from 'app/app.route';
import { history } from 'app/app.history';

import useSession from 'auth/hooks/use-session';

function Main(): JSX.Element {
  useSession();

  return (
    <Router history={history}>
      <AppRoute />
    </Router>
  );
}

export default Main;
