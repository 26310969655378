export type MayBe<T> = T | null;

export type TSVGComponent = React.FC<React.SVGProps<SVGSVGElement>>;

export type THRComponent = React.FC<React.HTMLAttributes<HTMLHRElement>>;

export type TInputChange = React.ChangeEvent<HTMLInputElement | HTMLSelectElement>;

export enum ETimezone {
  LOCAL = '',
  UTC = 'UTC',
  PST = 'PST',
  EST = 'EST',
  NPT = 'Asia/Kathmandu',
}
