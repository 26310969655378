import React from 'react';
import FormGroup from 'react-bootstrap/FormGroup';
import FormCheck, { FormCheckProps } from 'react-bootstrap/FormCheck';

import classNames from 'helpers/class.helper';

interface ICheckbox extends FormCheckProps {
  label: string;
}

const Checkbox = (props: ICheckbox): JSX.Element => {
  const { className, label, ...rest } = props;

  return (
    <FormGroup className={classNames('form-check-group', className)}>
      <FormCheck type='checkbox' label={label} {...rest} />
    </FormGroup>
  );
};

export default Checkbox;
