import React from 'react';

import { ICONS } from 'assets/icons';

interface ICaret {
  expanded?: boolean;
}

const Caret: React.FC<ICaret> = ({ expanded = false }): JSX.Element => {
  if (expanded) {
    return <ICONS.CARET_UP className='mb-2' />;
  }

  return <ICONS.CARET_DOWN />;
};

export default Caret;
