import React from 'react';

import classNames from 'helpers/class.helper';

export interface ISelectOption {
  name: string;
  value: string;
}
export interface ISelect extends React.SelectHTMLAttributes<HTMLSelectElement> {
  label?: string;
  inline?: boolean;
  options: Array<ISelectOption>;
}

const Select = (props: ISelect): JSX.Element => {
  const { label, options, placeholder, value, inline, ...rest } = props;

  return (
    <div className={classNames('raas-input-group', inline && 'inline')}>
      <label>{label}</label>
      <select value={value} className='form-control' {...rest}>
        {placeholder && <option value=''>{placeholder}</option>}
        {options.map((option, index) => (
          <option key={index} value={option.value}>
            {option.name}
          </option>
        ))}
      </select>
    </div>
  );
};

export default Select;
