import React from 'react';

import classNames from 'helpers/class.helper';

import { ReactComponent as Bell } from './bell.svg';
import { ReactComponent as Moon } from './moon.svg';
import { ReactComponent as Hold } from './hold.svg';
import { ReactComponent as Cross } from './cross.svg';
import { ReactComponent as Earth } from './earth.svg';
import { ReactComponent as Logout } from './logout.svg';
import { ReactComponent as Expand } from './expand.svg';
import { ReactComponent as Filter } from './filter.svg';
import { ReactComponent as Export } from './export.svg';
import { ReactComponent as History } from './history.svg';
import { ReactComponent as ArrowUp } from './arrow-up.svg';
import { ReactComponent as Initiated } from './initiated.svg';
import { ReactComponent as CaretDown } from './caret-down.svg';
import { ReactComponent as ArrowBack } from './arrow-back.svg';
import { ReactComponent as ArrowDown } from './arrow-down.svg';
import { ReactComponent as MinusCircle } from './minus-circle.svg';
import { ReactComponent as CloseCircle } from './close-circle.svg';
import { ReactComponent as SpinnerBlack } from './spinner-black.svg';
import { ReactComponent as DollarCircle } from './dollar-circle.svg';
import { ReactComponent as ArrowForward } from './arrow-forward.svg';

import Back from './back';
import Tick from './tick';
import EyeIcon from './eye';
import Copy from './copy';
import Invoice from './invoice';
import NavLeft from './nav-left';
import ThumpsUp from './thumbsup';
import NavRight from './nav-right';
import GroupIcon from './group-icon';
import DashboardIcon from './dashboard-icon';
import TransactionIcon from './transaction-icon';

interface IIcon extends React.SVGProps<SVGSVGElement> {
  title?: string;
  className?: string;
  onClick?: () => void;
}

export const CopyIcon: React.FC<IIcon> = (props) => <Copy {...props} />;
export const BellIcon: React.FC<IIcon> = (props) => <Bell {...props} />;
export const MoonIcon: React.FC<IIcon> = (props) => <Moon {...props} />;
export const BackIcon: React.FC<IIcon> = (props) => <Back {...props} />;
export const TickIcon: React.FC<IIcon> = (props) => <Tick {...props} />;
export const HoldIcon: React.FC<IIcon> = (props) => <Hold {...props} />;
export const CrossIcon: React.FC<IIcon> = (props) => <Cross {...props} />;
export const EarthIcon: React.FC<IIcon> = (props) => <Earth {...props} />;
export const LogoutIcon: React.FC<IIcon> = (props) => <Logout {...props} />;
export const ExpandIcon: React.FC<IIcon> = (props) => <Expand {...props} />;
export const FilterIcon: React.FC<IIcon> = (props) => <Filter {...props} />;
export const ExportIcon: React.FC<IIcon> = (props) => <Export {...props} />;
export const NavLeftIcon: React.FC<IIcon> = (props) => <NavLeft {...props} />;
export const InvoiceIcon: React.FC<IIcon> = (props) => <Invoice {...props} />;
export const HistoryIcon: React.FC<IIcon> = (props) => <History {...props} />;
export const ArrowUpIcon: React.FC<IIcon> = (props) => <ArrowUp {...props} />;
export const NavRightIcon: React.FC<IIcon> = (props) => <NavRight {...props} />;
export const InitiatedIcon: React.FC<IIcon> = (props) => <Initiated {...props} />;
export const ArrowBackIcon: React.FC<IIcon> = (props) => <ArrowBack {...props} />;
export const ArrowDownIcon: React.FC<IIcon> = (props) => <ArrowDown {...props} />;
export const MinusCircleIcon: React.FC<IIcon> = (props) => <MinusCircle {...props} />;
export const CloseCircleIcon: React.FC<IIcon> = (props) => <CloseCircle {...props} />;
export const DollarCircleIcon: React.FC<IIcon> = (props) => <DollarCircle {...props} />;
export const SpinnerBlackIcon: React.FC<IIcon> = (props) => <SpinnerBlack {...props} />;
export const ArrowForwardIcon: React.FC<IIcon> = (props) => <ArrowForward {...props} />;
export const CaretDownIcon: React.FC<IIcon> = ({ className, ...rest }) => (
  <CaretDown className={classNames(className, 'icon-caret')} {...rest} />
);

export const CaretUpIcon: React.FC<IIcon> = ({ className, ...rest }) => (
  <CaretDown className={classNames(className, 'icon-caret rotate-180')} {...rest} />
);

export const ICONS = {
  EYE: EyeIcon,
  BELL: BellIcon,
  COPY: CopyIcon,
  MOON: MoonIcon,
  TICK: TickIcon,
  HOLD: HoldIcon,
  BACK: BackIcon,
  EARTH: EarthIcon,
  GROUP: GroupIcon,
  CROSS: CrossIcon,
  LOGOUT: LogoutIcon,
  FILTER: FilterIcon,
  EXPORT: ExportIcon,
  EXPAND: ExpandIcon,
  THUMPS_UP: ThumpsUp,
  HISTORY: HistoryIcon,
  INVOICE: InvoiceIcon,
  CARET_UP: CaretUpIcon,
  NAV_LEFT: NavLeftIcon,
  ARROW_UP: ArrowUpIcon,
  NAV_RIGHT: NavRightIcon,
  INITIATED: InitiatedIcon,
  DASHBOARD: DashboardIcon,
  CARET_DOWN: CaretDownIcon,
  ARROW_DOWN: ArrowDownIcon,
  TRANSACTION: TransactionIcon,
  MINUS_CIRCLE: MinusCircleIcon,
  CLOSE_CIRCLE: CloseCircleIcon,
  ARROW_BACK: ArrowBackIcon,
  SPINNER_BLACK: SpinnerBlackIcon,
  DOLLAR_CIRCLE: DollarCircleIcon,
  ARROW_FORWARD: ArrowForwardIcon,
};
