import React from 'react';

import { TSVGComponent } from 'app/app.type';

const TransactionIcon: TSVGComponent = ({ fill = 'black', width = '18', height = '18', ...rest }): JSX.Element => {
  return (
    <svg width={width} height={height} viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg' {...rest}>
      <path
        d='M9.675 7.875C9.225 7.7625 8.775 7.5375 8.4375 7.2C8.1 7.0875 7.9875 6.75 7.9875 6.525C7.9875 6.3 8.1 5.9625 8.325 5.85C8.6625 5.625 9 5.4 9.3375 5.5125C10.0125 5.5125 10.575 5.85 10.9125 6.3L11.925 4.95C11.5875 4.6125 11.25 4.3875 10.9125 4.1625C10.575 3.9375 10.125 3.825 9.675 3.825V2.25H8.325V3.825C7.7625 3.9375 7.2 4.275 6.75 4.725C6.3 5.2875 5.9625 5.9625 6.075 6.6375C6.075 7.3125 6.3 7.9875 6.75 8.4375C7.3125 9 8.1 9.3375 8.775 9.675C9.1125 9.7875 9.5625 10.0125 9.9 10.2375C10.125 10.4625 10.2375 10.8 10.2375 11.1375C10.2375 11.475 10.125 11.8125 9.9 12.15C9.5625 12.4875 9.1125 12.6 8.775 12.6C8.325 12.6 7.7625 12.4875 7.425 12.15C7.0875 11.925 6.75 11.5875 6.525 11.25L5.4 12.4875C5.7375 12.9375 6.075 13.275 6.525 13.6125C7.0875 13.95 7.7625 14.2875 8.4375 14.2875V15.75H9.675V14.0625C10.35 13.95 10.9125 13.6125 11.3625 13.1625C11.925 12.6 12.2625 11.7 12.2625 10.9125C12.2625 10.2375 12.0375 9.45 11.475 9C10.9125 8.4375 10.35 8.1 9.675 7.875ZM9 0C4.05 0 0 4.05 0 9C0 13.95 4.05 18 9 18C13.95 18 18 13.95 18 9C18 4.05 13.95 0 9 0ZM9 16.7625C4.725 16.7625 1.2375 13.275 1.2375 9C1.2375 4.725 4.725 1.2375 9 1.2375C13.275 1.2375 16.7625 4.725 16.7625 9C16.7625 13.275 13.275 16.7625 9 16.7625Z'
        fill={fill}
      />
    </svg>
  );
};

export default TransactionIcon;
