import React from 'react';
import { Switch, Route, Redirect, useLocation } from 'react-router-dom';

import * as views from 'app/app.view';
import { ROUTES } from 'app/app.constant';
import Header from 'app/layout/app-header';

import { EApiStatus } from 'api/api.type';
import { useFilter } from 'transaction/filter/filter.context';
import AuthenticatedRoute from 'auth/routes/authenticated-route';

import { useAppState } from './app.context';

const AppRoute = (): JSX.Element => {
  return (
    <Switch>
      <Route path={ROUTES.LOGIN} component={views.Login} />
      <Route path={ROUTES.ROOT} component={MainRoute} />
    </Switch>
  );
};

export default AppRoute;

export const MainRoute = (): JSX.Element => {
  const { error } = useAppState();
  const location = useLocation();
  const { resetFiltered } = useFilter();

  if (error?.code === EApiStatus.TOKEN_EXPIRED) {
    resetFiltered();

    return (
      <Redirect
        to={{
          pathname: ROUTES.LOGIN,
          search: 'expired=true',
          state: { from: location.pathname },
        }}
      />
    );
  }

  return (
    <>
      <Header />
      <Switch>
        <AuthenticatedRoute path={ROUTES.CLIENTS} component={views.Clients} />
        <AuthenticatedRoute exact path={ROUTES.DASHBOARD} component={views.Dashboard} />

        <Route exact path={ROUTES.NOT_FOUND} component={views.NotFound} />
        <AuthenticatedRoute path={ROUTES.ROOT} component={views.TransactionRoute} />

        <Redirect to={ROUTES.NOT_FOUND} />
      </Switch>
    </>
  );
};
