import axios, { AxiosError, AxiosResponse } from 'axios';

import config from 'app/app.config';

import authApiUrl from 'auth/auth-api-url';

import { serialize } from 'helpers/object.helper';
import { withData, withError } from 'helpers/response.helper';
import { EStorageKey, securedLS } from 'helpers/storage.helper';

import { EApiStatus } from './api.type';

interface IAuthHeader {
  headers: {
    authorization: string;
  };
}

interface IApiOptions<D, P> {
  data?: D;
  params?: P;
  auth?: boolean;
}

export const paramSerializer = (params: Record<string, any>): string => {
  return Object.entries(params)
    .map(([key, value]) => `${key}=${value}`)
    .join('&');
};

const isAuthRequest = (url?: string) => [authApiUrl.LOGIN, authApiUrl.TOKEN, authApiUrl.LOGOUT].includes(url || '');

const client = axios.create({
  baseURL: config.API_URL,
  paramsSerializer: paramSerializer,
  headers: {
    'Content-Type': 'application/json',
  },
});

export const refresh = async (): Promise<boolean> => {
  try {
    const res = await axios.post(config.API_URL + authApiUrl.TOKEN, {
      token: securedLS.get(EStorageKey.TOKEN).data,
    });

    if (EApiStatus.OK === res.status && res.data?.token && res.data?.user) {
      securedLS.set(EStorageKey.TOKEN, res.data.token);
      securedLS.set(EStorageKey.CURRENT_USER, res.data.user);

      return true;
    }

    return false;
  } catch (error) {
    return false;
  }
};

client.interceptors.response.use(
  (response: AxiosResponse): any => {
    return withData(response.data);
  },
  (error: AxiosError): any => {
    const refreshToken = async () => {
      const refreshed = await refresh();

      if (!refreshed) {
        securedLS.clear(EStorageKey.TOKEN);
        securedLS.clear(EStorageKey.CURRENT_USER);

        securedLS.set(EStorageKey.LAST_VISITED_URL, window.location.pathname + window.location.search);

        return withError({
          message: error.response?.data.message,
          code: EApiStatus.TOKEN_EXPIRED,
          detail: serialize(error),
        });
      }

      error.config.headers.authorization = `Bearer ${securedLS.get(EStorageKey.TOKEN).data}`;

      return axios(error.config);
    };

    if (EApiStatus.UNAUTHENTICATED === error.response?.status && !isAuthRequest(error.config.url)) {
      return refreshToken();
    }

    return withError({
      message: error.response?.data.message,
      code: error.response?.status.toString(),
      detail: serialize(error),
    });
  }
);

export function get<P, D>(url: string, options?: IApiOptions<P, D>): Promise<any> {
  return client({
    url,
    method: 'get',
    params: options?.params,
    ...getRequestHeader(options?.auth),
  });
}

export function post<D, P>(url: string, options: IApiOptions<P, D>): Promise<any> {
  return client({
    url,
    method: 'post',
    data: options.data,
    params: options.params,
    ...getRequestHeader(options.auth),
  });
}

export function put<D, P>(url: string, options: IApiOptions<P, D>): Promise<any> {
  return client({
    url,
    method: 'put',
    data: options.data,
    params: options.params,
    ...getRequestHeader(options.auth),
  });
}

export function patch<D, P>(url: string, options: IApiOptions<P, D>): Promise<any> {
  return client({
    url,
    method: 'patch',
    data: options.data,
    params: options.params,
    ...getRequestHeader(options.auth),
  });
}

export function remove<P, D>(url: string, options: IApiOptions<P, D>): Promise<any> {
  return client({
    url,
    method: 'delete',
    data: options.data,
    params: options.params,
    ...getRequestHeader(options.auth),
  });
}

export const getRequestHeader = (authRequired = true): IAuthHeader | undefined => {
  if (authRequired) {
    return {
      headers: {
        authorization: `Bearer ${securedLS.get(EStorageKey.TOKEN).data}`,
      },
    };
  }
};
