import React, { lazy } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

import Loader from '@ui/loader';
import useLocale from 'app/hooks/use-locale';
import { ROUTES as APP_ROUTE } from 'app/app.constant';

import { ROUTES } from './transaction.constant';
import TransactionLayout from './transaction.layout';
import { TransactionProvider } from './context/transaction.context';

const TransactionView = lazy(() => import('./views/transaction.view'));
const TransactionReport = lazy(() => import('./views/transaction-report.view'));
const TransactionDetailView = lazy(() => import('./views/transaction-detail.view'));

const TransactionRoute = (): JSX.Element => {
  const { appText } = useLocale();

  return (
    <TransactionLayout>
      <React.Suspense fallback={<Loader message={appText('LOADING_MESSAGE')} />}>
        <TransactionProvider>
          <Switch>
            <Route exact path={APP_ROUTE.ROOT} component={TransactionView} />
            <Route exact path={ROUTES.TRANSACTIONS} component={TransactionView} />
            <Route exact path={ROUTES.DETAIL} component={TransactionDetailView} />
            <Route exact path={ROUTES.REPORTS} component={TransactionReport} />

            <Redirect to={ROUTES.NOT_FOUND} />
          </Switch>
        </TransactionProvider>
      </React.Suspense>
    </TransactionLayout>
  );
};

export default TransactionRoute;
