import Logo from './logo.svg';
import Buildings from './buildings.png';
import LogoSingle from './logo-single.svg';
import Construction from './construction.gif';
import DefaultAvatarMale from './default-avatar-male.svg';

export { Logo, DefaultAvatarMale, Construction, Buildings };

export default {
  LOGO: Logo,
  BUILDINGS: Buildings,
  LOGO_SINGLE: LogoSingle,
  CONSTRUCTION: Construction,
  DEFAULT_AVATAR_MALE: DefaultAvatarMale,
};
