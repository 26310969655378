import { TNameValue } from 'helpers/object.helper';
import { EStorageKey, securedLS } from 'helpers/storage.helper';

import { TAppAction } from './app.context';

export const setTimezone = (timezone: TNameValue): TAppAction => {
  securedLS.set(EStorageKey.TIME_ZONE, timezone);

  return {
    type: 'SET_TIMEZONE',
    payload: timezone,
  };
};

export const toggleSidebar = (): TAppAction => {
  return {
    type: 'TOGGLE_SIDEBAR',
  };
};
