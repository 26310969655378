import React from 'react';

import useLocale from 'app/hooks/use-locale';

const AppFooter = (): JSX.Element => {
  const { appText } = useLocale();

  return (
    <div className='raas-footer'>
      <p className='raas-footer__text'>&#169; {appText('FOOTER_TEXT')}</p>
    </div>
  );
};

export default AppFooter;
