import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timeZone from 'dayjs/plugin/timezone';

import { ETimezone } from 'app/app.type';
import { timezones } from 'app/data/timezone';

import { INameValue } from './object.helper';
import { EStorageKey, securedLS } from './storage.helper';

dayjs.extend(utc);
dayjs.extend(timeZone);

export const DEFAULT_FORMAT = 'MM/DD/YYYY';
const DATE_TIME_DEFAULT_FORMAT = 'YYYY-MM-DD HH:mm:ss';

export const API_FORMAT = 'YYYY-MM-DD';

export const DEFAULT_TIMEZONE = dayjs.tz.guess();

export const getTimezone = (): string => {
  const { error, data: timezone } = securedLS.get(EStorageKey.TIME_ZONE);

  return error || !timezone.value ? DEFAULT_TIMEZONE : timezone.value;
};

dayjs.tz.setDefault(getTimezone());

const getFormat = (value: ETimezone) => {
  return timezones.find((timezone) => timezone.value === value)?.format || '';
};

export const getDefaultTimezone = (): INameValue => ({
  name: getTimezoneName(),
  value: getTimezone(),
});

const getTimezoneFormat = (format: string) => {
  const name = getTimezoneName();
  const timezone = getTimezone();
  const timezoneFormat = getFormat(timezone as ETimezone);

  return name !== 'LOCAL' ? `${format} [${timezoneFormat}]` : format;
};

export const getTimezoneName = (): string =>
  Object.entries(ETimezone).find((tuple) => tuple[1] === getTimezone())?.[0] || 'LOCAL';

export const getDate = (dateString?: string): Date => {
  return dayjs(dateString).toDate();
};

export const getLocaleDate = (date?: Date | string, format = DEFAULT_FORMAT): string => {
  return dayjs.utc(date).local().format(format);
};

export const formatDateValue = (date: Date | string, format = DEFAULT_FORMAT): string => {
  return date === '-' ? '-' : dayjs(date).format(format);
};

export const formatDate = (date: Date | string, withTimezone = true, format = DEFAULT_FORMAT): string => {
  return withTimezone ? getUTC(date).tz(getTimezone()).format(getTimezoneFormat(format)) : dayjs(date).format(format);
};

export const formatApiDate = (date: Date | string): string => {
  return dayjs(date).format(API_FORMAT);
};

export const formatDateTime = (
  date?: Date | string,
  withTimezone = true,
  format = DATE_TIME_DEFAULT_FORMAT
): string => {
  return withTimezone ? getUTC(date).tz(getTimezone()).format(getTimezoneFormat(format)) : dayjs(date).format(format);
};

export const getUTC = (date?: Date | string) => {
  return dayjs.utc(date);
};

export const isBefore = (date: Date | string, compare?: Date | string): boolean => {
  return dayjs(date).isBefore(dayjs(compare));
};

export const isAfter = (date?: Date | string, compare?: Date | string): boolean => {
  return dayjs(date).isAfter(dayjs(compare));
};

export const getDateBefore = (date?: string | Date, days = 30): Date => {
  return dayjs(date).subtract(days, 'days').toDate();
};

export const getDateAfter = (date?: string | Date, days = 30): Date => {
  return dayjs(date).add(days, 'days').toDate();
};

export const getOneMonthPriorDate = () => {
  const date = new Date();
  date.setMonth(date.getMonth() - 1);

  return getLocaleDate(date);
};
