import { IPage, ITransaction } from 'transaction/transaction.type';

export interface ITransactionState {
  paging: IPage;
  fetching: boolean;
  transactions: ITransaction[];
}

export type TTransactionActions =
  | { type: 'SET_FETCHING' }
  | { type: 'RESET_FETCHING' }
  | { type: 'SET_PAGING'; payload: IPage }
  | { type: 'SET_TRANSACTIONS'; payload: ITransaction[] };

export type TTransactionDispatch = (action: TTransactionActions) => void;

const MAX_PAGE_SIZE = 100;

export const initialTransactionsState: ITransactionState = {
  paging: {
    page: 1,
    pageSize: 20,
    totalCount: 0,
    hasNext: false,
    maxPageSize: MAX_PAGE_SIZE,
  },
  fetching: false,
  transactions: [],
};

function transactionReducer(state: ITransactionState, action: TTransactionActions): ITransactionState {
  switch (action.type) {
    case 'SET_TRANSACTIONS': {
      return { ...state, transactions: action.payload };
    }

    case 'SET_FETCHING': {
      return { ...state, fetching: true };
    }

    case 'RESET_FETCHING': {
      return { ...state, fetching: false };
    }

    case 'SET_PAGING': {
      return { ...state, paging: { ...action.payload, maxPageSize: MAX_PAGE_SIZE } };
    }

    default: {
      throw new Error('Please select the appropriate action');
    }
  }
}

export default transactionReducer;
