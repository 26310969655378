import { ETransactionStatus } from 'transaction/transaction.enum';

const classNames = (...args: any[]): string => {
  return args.filter(Boolean).join(' ');
};

export default classNames;

export const getStatusClass = (status: string): string => {
  return (
    {
      [ETransactionStatus.HOLD]: 'warning',
      [ETransactionStatus.FAILED]: 'danger',
      [ETransactionStatus.PENDING]: 'info',
      [ETransactionStatus.INITIATED]: 'info',
      [ETransactionStatus.CANCELED]: 'danger',
      [ETransactionStatus.RETURNED]: 'danger',
      [ETransactionStatus.REFUNDED]: 'warning',
      [ETransactionStatus.PROCESSED]: 'success',
    }[status] || ''
  );
};
