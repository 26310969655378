import React from 'react';

import { TSVGComponent } from 'app/app.type';

const NavLeft: TSVGComponent = ({ fill = 'black', width = '8', height = '12', ...rest }): JSX.Element => {
  return (
    <svg
      fill='none'
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      xmlns='http://www.w3.org/2000/svg'
      {...rest}
    >
      <path
        d='M6.9607 0.327898C7.42298 0.778951 7.45926 1.40691 6.9607 1.95831L3.07688 6.00123L6.9607 10.0441C7.45926 10.5955 7.42298 11.2245 6.9607 11.6725C6.49945 12.1235 5.71999 12.0946 5.28673 11.6725C4.85346 11.2525 0.620342 6.8154 0.620342 6.8154C0.510624 6.70974 0.423359 6.5831 0.363757 6.44303C0.304155 6.30297 0.273438 6.15236 0.273438 6.00019C0.273438 5.84802 0.304155 5.69741 0.363757 5.55735C0.423359 5.41729 0.510624 5.29065 0.620342 5.18499C0.620342 5.18499 4.85346 0.749984 5.28673 0.327898C5.71999 -0.0952218 6.49945 -0.123154 6.9607 0.327898Z'
        fill={fill}
      />
    </svg>
  );
};

export default NavLeft;
