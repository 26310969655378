import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import { NotFound } from 'app/app.view';
import AppLayout from 'app/layout/app-layout';

import AddClient from './views/add-client';
import { ROUTES } from './client.constant';
import ClientList from './views/client-list';

const ClientRoute = (): JSX.Element => {
  return (
    <AppLayout>
      <Switch>
        <Route exact path={ROUTES.CLIENTS} component={ClientList} />
        <Route exact path={ROUTES.ADD} component={AddClient} />
        <Route exact path={ROUTES.LIST} component={ClientList} />
        <Route path={ROUTES.NOT_FOUND} component={NotFound} />
        <Redirect to={ROUTES.NOT_FOUND} />
      </Switch>
    </AppLayout>
  );
};

export default ClientRoute;
