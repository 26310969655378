import { IFilter, ITransactionFilter } from 'transaction/transaction.type';

export const initialTXNFilterState: ITransactionFilter = {
  email: '',
  status: '',
  endDate: '',
  startDate: '',
  affiliate: '',
  filtered: false,
  fundingSource: '',
  transactionNumber: '',
};

export const initialFilterState: IFilter = {
  transactionFilter: initialTXNFilterState,
};
