import React from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import { Link, useHistory } from 'react-router-dom';

import IMAGES from 'assets/images';
import { ICONS } from 'assets/icons';

import { ROUTES } from 'app/app.constant';
import { setTimezone } from 'app/app.action';
import useLocale from 'app/hooks/use-locale';
import { timezones } from 'app/data/timezone';
import { useApp, useAppState } from 'app/app.context';

import { useAuthState } from 'auth/auth.context';
import { logout as logoutSession } from 'auth/auth.api';

import classNames from 'helpers/class.helper';
import { TNameValue } from 'helpers/object.helper';
import { EStorageKey, securedLS } from 'helpers/storage.helper';

import Caret from '@ui/caret';
import { useFilter } from 'transaction/filter/filter.context';

const AppHeader = (): JSX.Element => {
  const history = useHistory();
  const { appText } = useLocale();
  const authState = useAuthState();
  const { resetTXNFilterState } = useFilter();
  const { timezone, dispatch } = useApp();
  const { sidebarExpanded } = useAppState();

  const logout = () => {
    resetTXNFilterState();
    securedLS.clear(EStorageKey.LAST_VISITED_URL);

    return logoutSession();
  };

  const getUserName = () => {
    return `${authState.user?.firstName || ''} ${authState.user?.lastName || ''}`;
  };

  const handleTimezoneChange = (timezone: TNameValue) => {
    dispatch(setTimezone(timezone));
  };

  const renderAuthenticatedHeader = () => {
    if (authState.authenticated) {
      return (
        <div className='raas-header__right'>
          <div className='icon-wrapper'>
            <ICONS.BELL />
          </div>
          <div className='icon-wrapper'>
            <ICONS.MOON />
          </div>

          <Dropdown>
            <Dropdown.Toggle variant='transparent' className='raas-header__dropdown--button timezone'>
              <ICONS.EARTH className='icon-earth' />
              <span className='user-name'>{timezone?.name || ''}</span>
              <div className='pl-2'>
                <Caret />
              </div>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {timezones.map((timezone, index) => (
                <Dropdown.Item key={index} onClick={() => handleTimezoneChange(timezone)}>
                  {timezone.name}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
          <Dropdown>
            <Dropdown.Toggle className='raas-header__dropdown--button' variant='transparent'>
              <span className='user-name'>{authState.user?.firstName}</span>
              <div className='icon-wrapper--rounded' role='alert'>
                <img src={IMAGES.DEFAULT_AVATAR_MALE} alt='default avatar' />
              </div>
              <div className='pl-2'>
                <Caret />
              </div>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <div className='raas-header__dropdown--item'>
                <span className='user-info'>{getUserName()}</span>
              </div>

              <div className='raas-header__dropdown--item'>
                <ICONS.LOGOUT />
                <Link to={ROUTES.LOGIN} className='btn' onClick={logout}>
                  {appText('LOGOUT')}
                </Link>
              </div>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      );
    }

    return null;
  };

  return (
    <header className='raas-header'>
      <div className='raas-container raas-header-wrapper'>
        <div
          role='button'
          onClick={() => history.push('/')}
          className={classNames('logo-wrapper', !sidebarExpanded ? 'single' : '')}
        >
          <img className='header-logo' src={sidebarExpanded ? IMAGES.LOGO : IMAGES.LOGO_SINGLE} alt='MACHNET' />
        </div>
        {renderAuthenticatedHeader()}
      </div>
    </header>
  );
};

export default AppHeader;
