import React from 'react';
import { default as ReactDatePicker, ReactDatePickerProps } from 'react-datepicker';

import { getDate } from 'helpers/date.helper';
import classNames from 'helpers/class.helper';

import 'react-datepicker/dist/react-datepicker.css';

interface IDatePicker extends ReactDatePickerProps {
  label?: string;
  inline?: boolean;
  invalid?: boolean;
}

const DatePicker = (props: IDatePicker): JSX.Element => {
  const { label, value, inline, invalid, ...rest } = props;

  const selected = value ? getDate(value) : null;

  return (
    <div className={classNames('raas-input-group', inline && 'inline')}>
      <label>{label}</label>
      <ReactDatePicker
        selected={selected}
        placeholderText='MM/DD/YYYY'
        className={classNames('form-control', invalid && 'is-invalid')}
        {...rest}
      />
    </div>
  );
};

export default DatePicker;
