import React from 'react';

interface IForm extends React.FormHTMLAttributes<HTMLFormElement> {
  onReset?: () => void;
  children: React.ReactNode;
  onSubmit: (data: Record<string, any>) => void;
}

export const Form = ({ children, onSubmit, onReset, ...rest }: IForm): JSX.Element => {
  const mapData = (e: React.FormEvent<HTMLFormElement>): Record<string, any> => {
    e.preventDefault();

    const data: Record<string, string> = {};

    for (const target of e.target as any) {
      if (target.value) {
        data[target.name] = target.value;
      }
    }

    return data;
  };

  return (
    <form onSubmit={(e) => onSubmit(mapData(e))} onReset={onReset} {...rest}>
      {children}
    </form>
  );
};

export { default as Input } from './input';
export { default as Select } from './select';
export { default as Checkbox } from './checkbox';
export { default as TextArea } from './text-area';
export { default as DatePicker } from './date-picker';
