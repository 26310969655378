import { QueryClient } from 'react-query';

import appConfig from './app.config';

export const client = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnMount: true,
      refetchOnWindowFocus: true,
      retryOnMount: true,
      staleTime: appConfig.APP_STALE_TIME,
      refetchInterval: appConfig.APP_STALE_TIME,
      retry: false,
    },
  },
});
