import React from 'react';
import { FormLabel } from 'react-bootstrap';
import FormControl, { FormControlProps } from 'react-bootstrap/FormControl';

export interface ITextArea extends FormControlProps {
  label: string;
  message?: string;
}
const TextArea = (props: ITextArea): JSX.Element => {
  const { label, message, ...rest } = props;

  return (
    <div className='text-area-wrapper'>
      <FormLabel>{label}</FormLabel>
      <FormControl as='textarea' aria-label={label} {...rest} />
      <FormControl.Feedback type='invalid'>{message}</FormControl.Feedback>
    </div>
  );
};

export default TextArea;
