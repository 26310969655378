import { useTranslation } from 'react-i18next';

import appText from 'lang/en/app.json';
import authText from 'lang/en/auth.json';
import errorText from 'lang/en/errors.json';

interface TranslationFunction {
  t: (key: string) => string;
}

interface IUseLocaleReturn {
  appText: (key: keyof typeof appText) => string;
  authText: (key: keyof typeof authText) => string;
  errorText: (key: keyof typeof errorText) => string;
}

const useLocale = (): IUseLocaleReturn => {
  const { t }: TranslationFunction = useTranslation();

  return {
    authText: (key: string) => t(`auth.${key}`),
    appText: (key: string) => t(`app.${key}`),
    errorText: (key: string) => t(`error.${key}`),
  };
};

export default useLocale;
