export interface IErrorProp {
  message?: string;
  [key: string]: any;
}

export interface IErrorReturn {
  data: null;
  error: any;
}

export interface IDataReturn {
  data: any;
  error: null;
}

export const isObject = <D>(arg: D): boolean => {
  return toString.call(arg) === '[object Object]';
};

export const withError = (arg: IErrorProp | string): IErrorReturn => {
  if (isObject(arg)) {
    const { message = '', ...rest } = arg as IErrorProp;

    return {
      data: null,
      error: {
        status: true,
        message,
        ...rest,
      },
    };
  }

  return {
    data: null,
    error: {
      status: true,
      message: arg,
    },
  };
};

export const withData = <D>(data: D): IDataReturn => {
  return {
    error: null,
    data,
  };
};
