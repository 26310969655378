import React from 'react';

import AppFooter from 'app/layout/app-footer';
import { useAppState } from 'app/app.context';
import AppSidebar from 'app/layout/app-sidebar';
import SIDEBAR_MENUS from 'app/data/sidebar-items';

import classNames from 'helpers/class.helper';

interface IDashboardLayout {
  children: React.ReactNode;
}

const AppLayout = (props: IDashboardLayout): JSX.Element => {
  const { children } = props;
  const { sidebarExpanded } = useAppState();

  const childClasses = classNames('app-layout__children', sidebarExpanded ? 'expanded' : '');

  return (
    <>
      <main className='raas-container app-layout-container'>
        <aside className='sidebar-container'>
          <AppSidebar items={SIDEBAR_MENUS} />
        </aside>
        <section className={childClasses}>
          <div className='page-container'>{children}</div>
        </section>
      </main>
      <footer>
        <AppFooter />
      </footer>
    </>
  );
};

export default AppLayout;
