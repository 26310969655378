import React from 'react';

import AppLayout from 'app/layout/app-layout';

interface ITransactionLayout {
  children: React.ReactNode;
}

const TransactionLayout: React.FC<ITransactionLayout> = ({ children }) => {
  return <AppLayout>{children}</AppLayout>;
};

export default TransactionLayout;
