import { TInputChange } from 'app/app.type';
import { formatApiDate } from 'helpers/date.helper';
import { IFilterValue, ITransactionFilter } from 'transaction/transaction.type';

import { TFilterAction } from './filter.type';

export const resetTXNFilter = (): TFilterAction => {
  return {
    type: 'RESET_TXN_FILTER',
  };
};

export const setTXNFilter = (filter: ITransactionFilter): TFilterAction => {
  return {
    type: 'SET_TXN_FILTER',
    payload: filter,
  };
};

export const setStartDate = (date?: Date): TFilterAction => {
  return {
    type: 'SET_START_DATE',
    payload: date ? formatApiDate(date) : '',
  };
};

export const setEndDate = (date?: Date): TFilterAction => {
  return {
    type: 'SET_END_DATE',
    payload: date ? formatApiDate(date) : '',
  };
};

export const setInputField = (e: TInputChange): TFilterAction => {
  return {
    type: 'SET_INPUT_FIELD',
    payload: {
      [e.target.name]: e.target.value,
    },
  };
};

export const setFilterValue = (props: IFilterValue): TFilterAction => {
  return {
    type: 'SET_INPUT_FIELD',
    payload: {
      [props.name]: props.value,
    },
  };
};

export const setFilteredState = (): TFilterAction => {
  return {
    type: 'SET_FILTERED',
  };
};

export const resetFilteredState = (): TFilterAction => {
  return {
    type: 'RESET_FILTERED',
  };
};
