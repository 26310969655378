import React from 'react';

import { TSVGComponent } from 'app/app.type';

const GroupIcon: TSVGComponent = ({ fill = 'black', width = '18', height = '18', ...rest }) => {
  return (
    <svg width={width} height={height} viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg' {...rest}>
      <path
        d='M6.75 1.75C5.95435 1.75 5.19129 2.06607 4.62868 2.62868C4.06607 3.19129 3.75 3.95435 3.75 4.75C3.75 5.54565 4.06607 6.30871 4.62868 6.87132C5.19129 7.43393 5.95435 7.75 6.75 7.75C7.54565 7.75 8.30871 7.43393 8.87132 6.87132C9.43393 6.30871 9.75 5.54565 9.75 4.75C9.75 3.95435 9.43393 3.19129 8.87132 2.62868C8.30871 2.06607 7.54565 1.75 6.75 1.75ZM2.25 4.75C2.25 3.55653 2.72411 2.41193 3.56802 1.56802C4.41193 0.724106 5.55653 0.25 6.75 0.25C7.94347 0.25 9.08807 0.724106 9.93198 1.56802C10.7759 2.41193 11.25 3.55653 11.25 4.75C11.25 5.94347 10.7759 7.08807 9.93198 7.93198C9.08807 8.77589 7.94347 9.25 6.75 9.25C5.55653 9.25 4.41193 8.77589 3.56802 7.93198C2.72411 7.08807 2.25 5.94347 2.25 4.75Z'
        fill={fill}
      />
      <path
        d='M2.343 12.2503H11.157C10.7467 10.6025 9.012 9.25027 6.75 9.25027C4.488 9.25027 2.75325 10.6025 2.343 12.2503ZM0.75 13.0003C0.75 9.99652 3.549 7.75027 6.75 7.75027C9.951 7.75027 12.75 9.99652 12.75 13.0003C12.75 13.1992 12.671 13.39 12.5303 13.5306C12.3897 13.6713 12.1989 13.7503 12 13.7503H1.5C1.30109 13.7503 1.11032 13.6713 0.96967 13.5306C0.829018 13.39 0.75 13.1992 0.75 13.0003ZM12.0007 7.65577C11.9116 7.81671 11.815 7.97339 11.7112 8.12527C12.1785 8.53777 12.594 9.00727 12.9465 9.52327C14.3497 9.99952 15.357 11.0458 15.657 12.2503H14.1C14.1982 12.7348 14.25 13.2365 14.25 13.7503H16.5C16.6989 13.7503 16.8897 13.6713 17.0303 13.5306C17.171 13.39 17.25 13.1992 17.25 13.0003C17.25 10.9063 15.8895 9.18052 13.9815 8.32702C14.7638 7.72988 15.3288 6.89274 15.5899 5.94384C15.851 4.99494 15.7938 3.98661 15.4271 3.07332C15.0604 2.16002 14.4044 1.39212 13.5596 0.887244C12.7148 0.382372 11.7278 0.168371 10.7497 0.278024C11.2511 0.727032 11.6737 1.25687 12 1.84552C12.6433 2.01261 13.213 2.38852 13.6197 2.91429C14.0264 3.44007 14.247 4.08596 14.247 4.75065C14.247 5.41534 14.0264 6.06123 13.6197 6.587C13.213 7.11278 12.6433 7.48869 12 7.65577H12.0007Z'
        fill={fill}
      />
    </svg>
  );
};

export default GroupIcon;
