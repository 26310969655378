import React from 'react';

import { TSVGComponent } from 'app/app.type';

const Copy: TSVGComponent = ({
  width = '16',
  height = '16',
  fill = 'currentColor',
  stroke = 'currentColor',
  ...rest
}) => {
  return (
    <svg
      role='button'
      width={width}
      height={height}
      aria-hidden='true'
      data-view-component='true'
      viewBox={`0 0 ${width} ${height}`}
      {...rest}
    >
      <path
        fillRule='evenodd'
        d='M0 6.75C0 5.784.784 5 1.75 5h1.5a.75.75 0 010 1.5h-1.5a.25.25 0 00-.25.25v7.5c0 .138.112.25.25.25h7.5a.25.25 0 00.25-.25v-1.5a.75.75 0 011.5 0v1.5A1.75 1.75 0 019.25 16h-7.5A1.75 1.75 0 010 14.25v-7.5z'
      ></path>
      <path
        fillRule='evenodd'
        d='M5 1.75C5 .784 5.784 0 6.75 0h7.5C15.216 0 16 .784 16 1.75v7.5A1.75 1.75 0 0114.25 11h-7.5A1.75 1.75 0 015 9.25v-7.5zm1.75-.25a.25.25 0 00-.25.25v7.5c0 .138.112.25.25.25h7.5a.25.25 0 00.25-.25v-7.5a.25.25 0 00-.25-.25h-7.5z'
      ></path>
    </svg>
  );
};

export default Copy;
