import React from 'react';

import { TSVGComponent } from 'app/app.type';

const Back: TSVGComponent = ({ width = '24', height = '24', fill = 'black', ...rest }) => {
  return (
    <svg
      width={width}
      height={height}
      fill='none'
      viewBox={`0 0 ${width} ${height}`}
      xmlns='http://www.w3.org/2000/svg'
    >
      <path d='M20 11H7.83L13.42 5.41L12 4L4 12L12 20L13.41 18.59L7.83 13H20V11Z' fill={fill} fillOpacity='0.54' />
    </svg>
  );
};

export default Back;
