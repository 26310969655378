import React from 'react';
import { ToastContainer } from 'react-toastify';

import { AuthProvider } from 'auth/auth.context';
import { FilterProvider } from 'transaction/filter/filter.context';

import Main from 'main';

import 'app/app.i18n';
import { AppProvider } from 'app/app.context';

import 'assets/scss/app.scss';

function App(): JSX.Element {
  return (
    <div className='app'>
      <AppProvider>
        <AuthProvider>
          <FilterProvider>
            <Main />
          </FilterProvider>
        </AuthProvider>
      </AppProvider>
      <ToastContainer autoClose={5000} />
    </div>
  );
}

export default App;
