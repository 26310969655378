import React from 'react';

import classNames from 'helpers/class.helper';

interface IInputField extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  inline?: boolean;
}

const InputField = (props: IInputField): JSX.Element => {
  const { label, inline, ...rest } = props;

  return (
    <div className={classNames('raas-input-group', inline && 'inline')}>
      <label>{label}</label>
      <input className='form-control' {...rest} />
    </div>
  );
};

export default InputField;
