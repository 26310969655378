import Nav from 'react-bootstrap/Nav';
import React, { useState } from 'react';
import NavItem from 'react-bootstrap/NavItem';
import Collapse from 'react-bootstrap/Collapse';
import { useHistory, useLocation } from 'react-router-dom';

import { THRComponent } from 'app/app.type';
import { toggleSidebar } from 'app/app.action';
import { useAppDispatch, useAppState } from 'app/app.context';

import { ICONS } from 'assets/icons';
import classNames from 'helpers/class.helper';

import { ISidebar, ISidebarItem } from './layout.type';

const AppSidebar: React.FC<ISidebar> = ({ items, depthStep, depth, expanded }): JSX.Element => {
  const dispatch = useAppDispatch();
  const { sidebarExpanded } = useAppState();

  return (
    <div className={classNames('app-sidebar', sidebarExpanded ? 'expanded' : '')}>
      <Nav className='flex-column'>
        <div className='expand-icon' role='button' onClick={() => dispatch(toggleSidebar())}>
          <ICONS.EXPAND />
        </div>
        <SidebarNavItems items={items} depth={depth} depthStep={depthStep} expanded={expanded} />
      </Nav>
    </div>
  );
};

export default AppSidebar;

const SidebarItem: React.FC<ISidebarItem> = ({ depthStep = 10, depth = 0, expanded, item, ...rest }) => {
  const history = useHistory();
  const { pathname } = useLocation();
  const [collapsed, setCollapsed] = useState(true);

  const { label, items, path } = item;
  const Icon = item.icon as any;

  const toggleCollapse = () => {
    setCollapsed((prevValue) => !prevValue);
  };

  const handleClick = () => {
    if (Array.isArray(items)) {
      toggleCollapse();

      return history.push(path);
    }

    return history.push(path);
  };

  const getCaretIcon = () => {
    if (Array.isArray(items) && items.length) {
      return !collapsed ? <ICONS.CARET_UP /> : <ICONS.CARET_DOWN />;
    }

    return null;
  };

  const isActive = pathname === path;

  return (
    <>
      <NavItem role='button' className={classNames('app-sidebar__item', isActive ? 'active' : '')} {...rest}>
        <div
          style={{ paddingLeft: depth * depthStep }}
          className='app-sidebar__item-content'
          onClick={handleClick}
          role='button'
        >
          <div className='app-sidebar__item-content__icon'>
            {Icon ? <Icon fill={isActive ? 'white' : 'black'} /> : null}
          </div>
          <div className='app-sidebar__item-content__label'>{label}</div>
        </div>
        <div className='app-sidebar__item-caret' onClick={toggleCollapse} role='button'>
          {getCaretIcon()}
        </div>
      </NavItem>
      <Collapse in={!collapsed} unmountOnExit>
        {Array.isArray(items) ? (
          <NavItem>
            <SidebarNavItems items={items} depth={depth + 1} />
          </NavItem>
        ) : (
          <div />
        )}
      </Collapse>
    </>
  );
};

const SidebarNavItems: React.FC<ISidebar> = ({ items, depth = 0, depthStep, expanded }): JSX.Element => {
  return (
    <>
      {items.map((subItem, index) => (
        <React.Fragment key={subItem === 'divider' ? 'divider' + depth + index : subItem.path + depth + index}>
          {'divider' === subItem ? (
            <Divider />
          ) : (
            <SidebarItem item={subItem} depth={depth} depthStep={depthStep} expanded={expanded} />
          )}
        </React.Fragment>
      ))}
    </>
  );
};

const Divider: THRComponent = (props): JSX.Element => {
  return <hr {...props} />;
};
