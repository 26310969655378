import React, { useState } from 'react';
import Alert from 'react-bootstrap/Alert';
import { Variant } from 'react-bootstrap/types';

import useLocale from 'app/hooks/use-locale';

interface IDismissibleAlert {
  title?: string;
  message?: string;
  variant?: Variant;
}

const DismissibleAlert: React.FC<IDismissibleAlert> = (props): JSX.Element => {
  const { appText } = useLocale();
  const [show, setShow] = useState(true);
  const { title = appText('WELCOME_BACK'), message = appText('EXPIRED_MESSAGE'), variant = 'primary' } = props;

  if (show) {
    return (
      <Alert variant={variant} onClose={() => setShow(false)} dismissible>
        <Alert.Heading>{title}</Alert.Heading>
        <p>{message}</p>
      </Alert>
    );
  }

  return <React.Fragment />;
};

export default DismissibleAlert;
