import React from 'react';

import { TSVGComponent } from 'app/app.type';

const Invoice: TSVGComponent = ({ fill = 'black', stroke = 'white', width = '18', height = '15', ...rest }) => {
  return (
    <svg width={width} height={height} viewBox='0 0 18 15' fill='none' xmlns='http://www.w3.org/2000/svg' {...rest}>
      <path
        d='M4.9501 9.81974C4.2751 9.93482 3.3751 9.47449 3.0376 9.35941L2.4751 10.3952C2.4751 10.3952 3.4876 10.8555 4.3876 10.9706V11.8912H5.5126V10.8555C6.5251 10.5102 7.0876 9.58957 7.2001 8.78399C7.2001 7.86332 6.5251 7.17282 5.0626 6.5974C4.6126 6.36724 3.8251 6.02199 3.8251 5.56165C3.8251 4.98624 4.2751 4.64099 4.9501 4.64099C5.7376 4.64099 6.5251 4.98624 6.5251 4.98624L6.9751 3.95049C6.9751 3.95049 6.4126 3.72032 5.6251 3.49015V2.68457H4.5001V3.49015C3.4876 3.72032 2.8126 4.41082 2.7001 5.44657C2.7001 6.82757 4.1626 7.40299 4.7251 7.63315C5.4001 7.86332 6.1876 8.32365 6.1876 8.6689C6.1876 9.12924 5.7376 9.70466 4.9501 9.81974Z'
        fill='white'
      />
      <path
        d='M2 0.382324C0.89543 0.382324 0 1.27775 0 2.38232V12.1923C0 13.2969 0.895431 14.1923 2 14.1923H16C17.1046 14.1923 18 13.2969 18 12.1923V2.38232C18 1.27775 17.1046 0.382324 16 0.382324H2ZM16.875 11.0415C16.875 12.1461 15.9796 13.0415 14.875 13.0415H3.125C2.02043 13.0415 1.125 12.1461 1.125 11.0415V3.53316C1.125 2.42859 2.02043 1.53316 3.125 1.53316H14.875C15.9796 1.53316 16.875 2.42859 16.875 3.53316V11.0415Z'
        fill='white'
      />
      <path d='M9 3.83496H15.75V4.98575H9V3.83496Z' fill='white' />
      <path d='M9 6.13672H15.75V7.28759H9V6.13672Z' fill='white' />
      <path d='M9 8.43848H12.375V9.58929H9V8.43848Z' fill='white' />
    </svg>
  );
};

export default Invoice;
