import React, { createContext, useContext, useReducer } from 'react';

import { TInputChange } from 'app/app.type';
import { IFilter, IFilterValue } from 'transaction/transaction.type';
import { IUseFilterReturn, TFilterDispatch } from 'transaction/filter/filter.type';

import filterReducer from './filter.reducer';
import { initialFilterState } from './filter.data';
import { resetFilteredState, resetTXNFilter, setFilteredState, setFilterValue, setInputField } from './filter.action';

const FilterStateContext = createContext<IFilter | undefined>(undefined);
const FilterDispatchContext = createContext<TFilterDispatch | undefined>(undefined);

function FilterProvider({ children }: { children: React.ReactNode }): JSX.Element {
  const [state, dispatch] = useReducer(filterReducer, initialFilterState);

  return (
    <FilterStateContext.Provider value={state}>
      <FilterDispatchContext.Provider value={dispatch as any}>{children}</FilterDispatchContext.Provider>
    </FilterStateContext.Provider>
  );
}

function useFilterState(): IFilter {
  const context = useContext(FilterStateContext);

  if (context === undefined) {
    throw new Error('Must be within the filter context');
  }

  return context;
}

function useFilterDispatch(): TFilterDispatch {
  const context = useContext(FilterDispatchContext);

  if (context === undefined) {
    throw new Error('Must be within the filter context');
  }

  return context;
}

function useFilter(): IUseFilterReturn {
  const dispatch = useFilterDispatch();
  const state = useFilterState();

  const handleChange = (e: TInputChange) => {
    dispatch(setInputField(e));
  };

  const setValue = (value: IFilterValue) => {
    dispatch(setFilterValue(value));
  };

  const setFiltered = () => {
    dispatch(setFilteredState());
  };

  const resetFiltered = () => {
    dispatch(resetFilteredState());
  };

  const resetTXNFilterState = () => {
    dispatch(resetTXNFilter());
  };

  return { state, dispatch, handleChange, setFiltered, resetFiltered, resetTXNFilterState, setValue };
}

export { FilterProvider, useFilterDispatch, useFilterState, useFilter };
