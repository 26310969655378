export interface IApiError {
  status: boolean;
  message: string;
  code: string | number;
}

export interface IApiResponse {
  data: any;
  error: any;
}

export enum EApiStatus {
  OK = 200,
  CREATED = 201,
  FORBIDDEN = 403,
  NOT_FOUND = 404,
  BAD_REQUEST = 400,
  TOKEN_EXPIRED = 418,
  UNAUTHENTICATED = 401,
  INTERNAL_SERVER_ERROR = 500,
}
