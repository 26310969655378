import React from 'react';

import { TSVGComponent } from 'app/app.type';

const NavRight: TSVGComponent = ({ fill = 'black', width = '8', height = '12', ...rest }): JSX.Element => {
  return (
    <svg
      fill='none'
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      xmlns='http://www.w3.org/2000/svg'
      {...rest}
    >
      <path
        d='M0.400876 11.6721C0.00463145 11.2211 -0.0264641 10.5931 0.400876 10.0417L3.72986 5.99878L0.400875 1.95586C-0.0264649 1.40446 0.00463053 0.775466 0.400875 0.327518C0.796231 -0.123535 1.46434 -0.0945678 1.83571 0.327518C2.20707 0.747534 5.83547 5.18461 5.83547 5.18461C5.92951 5.29027 6.00431 5.4169 6.0554 5.55697C6.10648 5.69703 6.13281 5.84764 6.13281 5.99981C6.13281 6.15198 6.10648 6.30259 6.0554 6.44265C6.00431 6.58271 5.92951 6.70935 5.83547 6.81501C5.83547 6.81501 2.20708 11.25 1.83571 11.6721C1.46434 12.0952 0.796232 12.1232 0.400876 11.6721Z'
        fill={fill}
      />
    </svg>
  );
};

export default NavRight;
