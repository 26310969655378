import React from 'react';

import { ICONS } from 'assets/icons';

export interface ISidebarMenu {
  path: string;
  label: string;
  icon?: React.ReactNode;
  items?: TSidebarItemList;
}

export type TSidebarItem = ISidebarMenu | 'divider';

export type TSidebarItemList = TSidebarItem[];

const SIDEBAR_MENUS: TSidebarItemList = [
  { path: '/dashboard', label: 'Dashboard', icon: ICONS.DASHBOARD },
  { path: '/', label: 'Transactions', icon: ICONS.TRANSACTION },
  {
    path: '/clients',
    label: 'Clients',
    icon: ICONS.GROUP,
    items: [
      { path: '/clients/list', label: 'Clients List' },
      {
        path: '/clients/add',
        label: 'Add Client',
      },
    ],
  },
];

export default SIDEBAR_MENUS;
