import React from 'react';
import { Link } from 'react-router-dom';

import useLocale from 'app/hooks/use-locale';

const NotFound = (): JSX.Element => {
  const { appText } = useLocale();

  return (
    <div className='not-found'>
      <p title='' className='not-found__title'>
        ಠ_ಠ
      </p>
      <p title='' className='not-found__sub-title'>
        {appText('NOT_FOUND_TEXT')}
      </p>
      <Link className='not-found__link' to='/'>
        {appText('TAKE_ME_HOME')}
      </Link>
    </div>
  );
};

export default NotFound;
