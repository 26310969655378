import React from 'react';
import { Redirect, withRouter } from 'react-router-dom';

import { ROUTES } from 'app/app.constant';
import { EStorageKey, securedLS } from 'helpers/storage.helper';

import { RoleBasedRoute } from './role-based-route';

const AuthenticatedRoute = (props: any): JSX.Element => {
  if (window.location.search.includes('token')) {
    securedLS.set(EStorageKey.LAST_VISITED_URL, window.location.pathname + window.location.search);
  }

  if (securedLS.get(EStorageKey.TOKEN).data) {
    return <RedirectPage {...props} />;
  }

  securedLS.clear(EStorageKey.TOKEN);

  return <Redirect to={ROUTES.LOGIN} />;
};

const RedirectPage = (props: any) => {
  return <RoleBasedRoute userRoles={props.userRoles} {...props} />;
};

export default withRouter(AuthenticatedRoute);
