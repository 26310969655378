import { EStorageKey, securedLS } from 'helpers/storage.helper';

import { AUTH } from './auth.constant';
import { ITokenResponse } from './auth.type';

interface IActionReturn {
  type: string;
  payload?: Record<string, any>;
}

export const setAuthenticationSuccess = (tokenPayload: ITokenResponse): IActionReturn => {
  securedLS.set(EStorageKey.TOKEN, tokenPayload.token);
  securedLS.set(EStorageKey.CURRENT_USER, tokenPayload.user);
  securedLS.clear(EStorageKey.LAST_VISITED_URL);

  return {
    type: AUTH.AUTHENTICATE_SUCCESS,
    payload: tokenPayload,
  };
};

export const setAuthenticationFailure = (): IActionReturn => {
  securedLS.clear(EStorageKey.TOKEN);
  securedLS.clear(EStorageKey.EMAIL);

  return {
    type: AUTH.AUTHENTICATE_FAILURE,
  };
};

export const authenticate = (): IActionReturn => {
  return {
    type: AUTH.AUTHENTICATE,
  };
};
