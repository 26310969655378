import React, { lazy } from 'react';

import Loader from '@ui/loader';
import useLocale from 'app/hooks/use-locale';
import AppLayout from 'app/layout/app-layout';

const DashboardView = lazy(() => import('./views/dashboard.view'));

const DashboardRoute = (): JSX.Element => {
  const { appText } = useLocale();

  return (
    <AppLayout>
      <React.Suspense fallback={<Loader message={appText('LOADING_MESSAGE')} />}>
        <DashboardView />
      </React.Suspense>
    </AppLayout>
  );
};

export default DashboardRoute;
