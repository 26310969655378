export interface INameValue {
  name: string;
  value: unknown;
  format?: string;
}

export type TNameValue = Record<string, string> & INameValue;

export const filterObjectByValues = (obj: Record<string, any>): Record<string, any> => {
  return Object.keys(obj).reduce<Record<string, any>>((acc, key) => {
    if (obj[key]) {
      acc[key] = obj[key];
    }

    return acc;
  }, {});
};

export const parse = <T>(jsonString: string): T => JSON.parse(jsonString);

export const serialize = (obj: unknown): string => JSON.stringify(obj);

export const enumerate = (obj = {}): INameValue[] => Object.entries(obj).map(([name, value]) => ({ name, value }));

export const createObjectWithValues = <ReturnType>(obj: Record<string, any>, excludingKeys = ['']) => {
  const params: any = {};

  for (const [key, value] of Object.entries(obj)) {
    if (excludingKeys.length && excludingKeys.includes(key)) {
      continue;
    }

    if (value) {
      params[key as keyof ReturnType] = value;
    }
  }

  return params as ReturnType;
};

/**
 * This is simple implementation for Immutable map for nested objects
 * Created to use for test environment only
 * Do not use in production code!!
 */

class IMap {
  __original: any = {};
  __temp: any = {};

  constructor(obj = {}) {
    this.__original = { ...obj };
    this.__temp = { ...obj };
  }

  of() {
    return this.__temp;
  }

  __getKeys(key: string) {
    return key?.split('.') ?? {};
  }

  __getCur(key: string) {
    const keys = this.__getKeys(key);
    let __cur = this.__temp;

    if (keys.length === 1) {
      return __cur;
    }

    while (keys.length > 1) {
      if (!__cur) break;
      __cur = __cur[keys.shift() || ''];
    }

    return __cur || {};
  }

  __lastKey(key: string): string {
    return this.__getKeys(key).pop() || '';
  }

  get(key: string) {
    return this.__getCur(key)[this.__lastKey(key)];
  }
  set(key: string, value: any) {
    this.__getCur(key)[this.__lastKey(key)] = value;

    return this;
  }
}

export const Obj = (obj: any) => new IMap(obj);
