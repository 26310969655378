import React from 'react';
import Image from 'react-bootstrap/Image';

import IMAGES from 'assets/images';
import useLocale from 'app/hooks/use-locale';

const ComingSoon = (): JSX.Element => {
  const { appText } = useLocale();

  return (
    <div className='card coming-soon-wrapper'>
      <h3>{appText('COMING_SOON')}</h3>
      <p>{appText('COMING_SOON_TEXT')}</p>
      <div className='image-wrapper'>
        <Image src={IMAGES.BUILDINGS} className='img-fluid' />
      </div>
    </div>
  );
};

export default ComingSoon;
