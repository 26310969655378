import appEn from './en/app.json';
import authEn from './en/auth.json';
import errorsEn from './en/errors.json';

const resources = {
  en: {
    translation: {
      app: { ...appEn },
      auth: { ...authEn },
      error: { ...errorsEn },
    },
  },
};

export default resources;
