import React from 'react';
import { Route } from 'react-router-dom';

export const userRoles = {
  ADMIN: 'ADMIN',
};

type TRoleBasedRoute = any;
type TComponentAccess = any;

export const isAuthorized = (grantedRoles = [], userRoles = []): any => {
  let permissionGranted = false;

  if (grantedRoles.length < 1) {
    return true;
  }
  userRoles.forEach((userRole) => {
    if (grantedRoles.includes(userRole)) {
      permissionGranted = true;
    }
  });

  return permissionGranted;
};

export const RoleBasedRoute = ({
  component: Component,
  roles: grantedRoles,
  userRoles,
  ...props
}: TRoleBasedRoute): JSX.Element => {
  return (
    <>
      {isAuthorized(grantedRoles, userRoles) && <Route {...props} render={(props) => <Component {...props} />} />}
      {!isAuthorized(grantedRoles, userRoles) && <Route render={() => <>Unauthorized Access.</>} />}
    </>
  );
};

export const ComponentAccess = ({ children, roles, ...props }: TComponentAccess): JSX.Element => {
  const isPermitted = isAuthorized(roles, props.userRoles);

  return <>{isPermitted && children}</>;
};
