import { ETimezone } from 'app/app.type';

export const timezones = [
  {
    name: 'LOCAL',
    value: ETimezone.LOCAL,
    format: '',
  },
  {
    name: 'UTC (GMT)',
    value: ETimezone.UTC,
    format: 'UTC',
  },
  {
    name: 'EST (GMT-05:00)',
    value: ETimezone.EST,
    format: 'EST',
  },
  {
    name: 'PST (GMT-07:00)',
    value: ETimezone.PST,
    format: 'PST',
  },
  {
    name: 'NPT (GMT+05:45)',
    value: ETimezone.NPT,
    format: 'NPT',
  },
];
