import { useEffect } from 'react';

import { useApp } from 'app/app.context';
import { setTimezone } from 'app/app.action';

import { TNameValue } from 'helpers/object.helper';
import { getDefaultTimezone } from 'helpers/date.helper';
import { EStorageKey, securedLS } from 'helpers/storage.helper';

import { useAuthDispatch } from 'auth/auth.context';
import { setAuthenticationFailure, setAuthenticationSuccess } from 'auth/auth.actions';

const useSession = (): void => {
  const dispatch = useAuthDispatch();
  const { dispatch: appDispatch } = useApp();

  useEffect(() => {
    const { data: timezone } = securedLS.get(EStorageKey.TIME_ZONE);
    const { data: token, error: tokenError } = securedLS.get(EStorageKey.TOKEN);
    const { data: user, error: userError } = securedLS.get(EStorageKey.CURRENT_USER);

    if (tokenError || userError) {
      securedLS.clear(EStorageKey.TOKEN);

      return dispatch(setAuthenticationFailure());
    }

    if (timezone) {
      appDispatch(setTimezone(timezone));
    } else {
      appDispatch(setTimezone(getDefaultTimezone() as TNameValue));
    }

    if (token && user) {
      return dispatch(
        setAuthenticationSuccess({
          token,
          user,
        })
      );
    }
  }, [dispatch, appDispatch]);
};

export default useSession;
